import { request } from "@/query/request";
import { ReadReceiptParam } from "@/types/Status";

export const readReceipt = async () =>
  await request({
    url: `status/receipt`,
    method: "get",
  });

export const readReceiptDocument = async (data: ReadReceiptParam) =>
  await request(
    {
      url: `status/receipt_document?id=${data.id}`,
      method: "get",
      responseType: "blob",
    },
    { isGetRaw: true },
  );
